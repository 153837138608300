// import { HomePageCompanyLogo } from '@backstage/plugin-home';
// import Grid from '@material-ui/core/Grid';
// import React from 'react';

// // export const HomePage = () => (
// //     /* We will shortly compose a pretty homepage here. */
// //     <h1>Welcome to Backstage!</h1>
// // );


// export const HomePage = (
//     <Grid container spacing={3}>
//         <Grid item xs={12} md={4}>
//             <HomePageCompanyLogo />
//         </Grid>
//     </Grid>
// );


/*
* Copyright 2021 The Backstage Authors
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*     http://www.apache.org/licenses/LICENSE-2.0
*
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */


import { Content, ItemCardGrid, ItemCardHeader, LinkButton, Page } from '@backstage/core-components';
import {
    HomePageCompanyLogo,
    HomePageStarredEntities
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import {
    SearchContextProvider
} from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import LogoFull from '../Root/LogoFullBlack';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles(theme => ({
    searchBarInput: {
        maxWidth: '60vw',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '50px',
        boxShadow: theme.shadows[1],
    },
    headerGreen: { backgroundImage: 'linear-gradient(to bottom right, green, lightgreen)', },
    headerPurple: { backgroundImage: 'linear-gradient(to bottom right, purple, pink)', },
    headerBlue: { backgroundImage: 'linear-gradient(to bottom right, darkblue, cyan)', },
    headerYellow: { backgroundImage: 'linear-gradient(to bottom right, orange, yellow)', },
    searchBarOutline: {
        borderStyle: 'none'
    }
}));

const useLogoStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(5, 0),
    },
    svg: {
        width: 'auto',
        height: 100,
    },
    path: {
        fill: '#7df3e1',
    },
}));

export const HomePage = () => {
    const classes = useStyles();
    const { container } = useLogoStyles();

    return (
        <SearchContextProvider>
            <Page themeId="home">
                <Content>
                    <Grid container justifyContent="center" spacing={6}>
                        <HomePageCompanyLogo
                            className={container}
                            logo={<LogoFull />}
                        />
                        <Grid container item xs={12} justifyContent='center'>
                            <p>
                                Welcome to <strong>Backstage</strong>, our central information hub for everything technical.
                                <br />
                                Search for what you need or click around - it's in here somewhere!
                            </p>
                        </Grid>
                        <Grid container item xs={12} justifyContent='center'>
                            <HomePageSearchBar
                                InputProps={{ classes: { root: classes.searchBarInput, notchedOutline: classes.searchBarOutline } }}
                                placeholder="Search"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid justifyContent='center'>
                        <HomePageStarredEntities {...{ title: "Your starred resources" }} />
                    </Grid>
                    <br />
                    <ItemCardGrid>
                        <Card key="docs-sdlc">
                            <CardMedia>
                                <ItemCardHeader
                                    subtitle="Docs"
                                    title="Software Development Lifecycle"
                                    classes={{ root: classes.headerPurple }}
                                />
                            </CardMedia>
                            <CardContent>
                                <p>
                                    The documentation linked below will get you up to speed on our SDLC practices..
                                </p>
                            </CardContent>
                            <CardActions>
                                <LinkButton color="primary" to="https://backstage.synpulse8.com/docs/default/component/software-development-guidelines">
                                    ↪  View materials
                                </LinkButton>
                            </CardActions>
                        </Card>

                        <Card key="docs-backstage">
                            <CardMedia>
                                <ItemCardHeader
                                    subtitle="Docs"
                                    title="Using Backstage"
                                    classes={{ root: classes.headerBlue }}
                                />
                            </CardMedia>
                            <CardContent>
                                <p>
                                    Need to add a new Component to Backstage, or update a docs site? Have a look at these docs.
                                </p>
                            </CardContent>
                            <CardActions>
                                <LinkButton color="primary" to="https://backstage.synpulse8.com/docs/default/component/backstage-tutorials">
                                    ↪ View materials
                                </LinkButton>
                            </CardActions>
                        </Card>

                        <Card key="docs-create-new-project">
                            <CardMedia>
                                <ItemCardHeader
                                    subtitle="Docs - Pulse8 CLI"
                                    title="Create a new project"
                                    classes={{ root: classes.headerGreen }}
                                />
                            </CardMedia>
                            <CardContent>
                                <p>
                                    Need to set up a new project? This guide will help you get started with our best-practice templates.
                                </p>
                            </CardContent>
                            <CardActions>
                                <LinkButton color="primary" to="https://backstage.synpulse8.com/docs/default/component/pulse8-core-cli">
                                    ↪ View materials
                                </LinkButton>
                            </CardActions>
                        </Card>

                        <Card key="docs-video-playlist">
                            <CardMedia>
                                <ItemCardHeader
                                    subtitle="Docs - Pulse8 tutorials"
                                    title="Tutorial videos 🎥"
                                    classes={{ root: classes.headerYellow }}
                                />
                            </CardMedia>
                            <CardContent>
                                <p>
                                    Check out various tutorials on accessing Pulse8 resources, working with Kubernetes, our GitOps deployment model, and everything in between.
                                </p>
                            </CardContent>
                            <CardActions>
                                <LinkButton color="primary" to="https://spextranet.sharepoint.com/:l:/s/s8/FCcCoN6hVapBgZHVMhU08dUBtjeZg2TxIn4MF-MCKGJCdw?e=twEAuV">
                                    ↪ View materials
                                </LinkButton>
                            </CardActions>
                        </Card>
                    </ItemCardGrid>
                </Content>
            </Page>
        </SearchContextProvider >
    );
};